

































import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { getPeople } from '../../../store';
import { ContentEntityPeople } from '@/types';
import MediaHandler from '@/components/utilities/media/MediaHandler';
import IconHandler from '@/components/utilities/icons/IconHandler';
import LinkManual from '@/components/utilities/links/LinkManual';

@Component({
  components: { MediaHandler, IconHandler, LinkManual }
})
export default class PeopleTeaser extends Vue {
  @Prop() uuid!: string;
  @Prop({ required: false, default: false }) in_slider?: boolean;
  get person (): ContentEntityPeople|false {
    return getPeople(this.$store).dataByUuid(this.uuid);
  }
}
