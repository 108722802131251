


























import { Vue, Component, Prop, Watch, mixins } from 'nuxt-property-decorator';
import { BlokPeopleSlider } from '../../types';
import { getPeople } from '../../store';
import BreakpointMixin from '../../mixins/BreakpointMixin';
import PeopleTeaser from '@/components/items/teasers/PeopleTeaser';

@Component({
  components: { PeopleTeaser }
})
export default class PeopleSlider extends mixins(BreakpointMixin) {
  @Prop() blok!: BlokPeopleSlider;
  get uuids (): string[] {
    if (!this.blok.mode) { return []; }
    const uuids = this.blok.mode === 'selected' ? this.blok.selected_people || [] : getPeople(this.$store).allUuids;
    if (process.browser && localStorage.getItem('not-first-timer') && !JSON.parse(localStorage.getItem('not-firsttimer'))) {
      return uuids.sort((a, b) => 0.5 - Math.random());
    } else {
      return uuids;
    }
  }
  // -> swiper
  $refs!: { container: HTMLElement, items: HTMLElement[] };
  route: any = this.$route;
  @Watch('route', {
    deep: true
  })
  routeChanged (newVal: any, oldVal: any): void {
    if (!process.browser) { return; }
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mousemove', this.handleMouseUp);
  }
  pos: any = { top: 0, left: 0, x: 0, y: 0 };
  handleSlider (isVisible: boolean, entry: IntersectionObserverEntry): void {
    if (matchMedia('(hover: none), (pointer: coarse)').matches) { return; }
    if (!this.$refs.container) { return; }
    if (isVisible) {
      const ct = this.$refs.container as HTMLElement;
      this.$refs.container.addEventListener('mousedown', (e: MouseEvent) => {
        this.pos = {
          left: ct.scrollLeft,
          top: 0,
          x: e.clientX,
          y: 0
        };
        ct.style.cursor = 'grabbing';
        ct.style.userSelect = 'none';
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
      });
    } else {
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mousemove', this.handleMouseUp);
    }
  }
  handleMouseMove (e: MouseEvent) {
    if (!this.$refs.container) { return; }
    const ct = this.$refs.container as HTMLElement;
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;
    ct.scrollTop = this.pos.top - dy;
    ct.scrollLeft = this.pos.left - dx;
  }
  handleMouseUp (e: MouseEvent) {
    if (!this.$refs.container) { return; }
    const ct = this.$refs.container as HTMLElement;
    ct.style.cursor = 'grab';
    ct.style.userSelect = 'unset';
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mousemove', this.handleMouseUp);
  }
  // -> GSAP animation
  animate (): void {
    this.$gsap.to(this.$refs.items, {
      scrollTrigger: {
        trigger: () => this.$refs.container,
        start: this.breakpointUp('md') ? 'top bottom-=200px' : 'top bottom-=50px'
      },
      stagger: {
        each: 0.1,
        ease: 'power1.out'
      },
      x: 0,
      opacity: 1
    });
  }
  mounted () {
    if (!process.browser) { return; }
    this.$gsap.set(this.$refs.items, {
      x: this.breakpointUp('md') ? 400 : 30,
      opacity: 0
    });
    this.animate();
  }
}
