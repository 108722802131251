import { render, staticRenderFns } from "./PeopleSlider.vue?vue&type=template&id=573199e8&scoped=true&"
import script from "./PeopleSlider.vue?vue&type=script&lang=ts&"
export * from "./PeopleSlider.vue?vue&type=script&lang=ts&"
import style0 from "./PeopleSlider.vue?vue&type=style&index=0&id=573199e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "573199e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PeopleTeaser: require('/opt/build/repo/components/items/teasers/PeopleTeaser.vue').default})
